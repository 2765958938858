var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "burger",
      class: { active: _vm.isNavOpen },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.toggle.apply(null, arguments)
        },
      },
    },
    [
      _vm._t("default", function () {
        return [_vm._m(0)]
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "burger-button",
        attrs: { type: "button", title: "Menu" },
      },
      [
        _c("span", { staticClass: "hidden" }, [_vm._v("Toggle menu")]),
        _c("span", { staticClass: "burger-bar burger-bar--1" }),
        _c("span", { staticClass: "burger-bar burger-bar--2" }),
        _c("span", { staticClass: "burger-bar burger-bar--3" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }