var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar",
      attrs: { tabindex: "0" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeSidebarPanel.apply(null, arguments)
        },
      },
    },
    [
      _vm.isNavOpen
        ? _c("div", {
            staticClass: "sidebar-backdrop",
            on: { click: _vm.closeSidebarPanel },
          })
        : _vm._e(),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.isNavOpen
          ? _c(
              "div",
              { staticClass: "sidebar-panel" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "window-close" },
                  on: { click: _vm.closeSidebarPanel },
                }),
                _vm._t("default"),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }