var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "cedia-header" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "router-link",
          {
            staticClass: "home",
            attrs: {
              "active-class": "active",
              exact: "",
              to: { name: "home" },
            },
          },
          [
            _c("div", { staticClass: "logo" }),
            _c("span", {
              staticClass: "city",
              attrs: { title: _vm.currentExpo.ville },
              domProps: { textContent: _vm._s(_vm.getTitle()) },
            }),
            _c("div", {
              staticClass: "offline",
              class: { show: this.showOffline },
            }),
          ]
        ),
        _c("burger"),
        _c("sidebar", [
          !_vm.isAuthenticated
            ? _c("ul", [
                _c(
                  "li",
                  { staticClass: "home" },
                  [
                    _c("router-link", { attrs: { to: { name: "home" } } }, [
                      _vm._v(" " + _vm._s(_vm.$t("nav.title")) + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "entry" },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "home" } }),
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          "active-class": "active",
                          exact: "",
                          to: { name: "home" },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("nav.home")) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { staticClass: "entry" },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "sign-in-alt" } }),
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          "active-class": "active",
                          exact: "",
                          to: { name: "login" },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("nav.login")) + " ")]
                    ),
                  ],
                  1
                ),
              ])
            : _c("ul", [
                _c(
                  "li",
                  { staticClass: "home" },
                  [
                    _c("router-link", { attrs: { to: { name: "home" } } }, [
                      _vm._v(" " + _vm._s(_vm.$t("nav.title")) + " "),
                    ]),
                  ],
                  1
                ),
                !_vm.isValidated
                  ? _c(
                      "li",
                      { staticClass: "entry" },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "dog" } }),
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              "active-class": "active",
                              to: { name: "expos" },
                            },
                          },
                          [
                            _c("i", { staticClass: "ion-compose" }),
                            _vm._v(_vm._s(_vm.$t("nav.shows")) + " "),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isValidated
                  ? _c(
                      "li",
                      { staticClass: "entry" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "sign-in-alt" },
                        }),
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              "active-class": "active",
                              exact: "",
                              to: { name: "rewards" },
                            },
                          },
                          [
                            _c("i", { staticClass: "ion-compose" }),
                            _vm._v(_vm._s(_vm.$t("nav.rewards")) + " "),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.canShowPreview
                  ? _c(
                      "li",
                      { staticClass: "entry" },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "check" } }),
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              "active-class": "active",
                              exact: "",
                              to: { name: "validation" },
                            },
                          },
                          [
                            _c("i", { staticClass: "ion-compose" }),
                            _vm._v(_vm._s(_vm.$t("nav.validation")) + " "),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "li",
                  { staticClass: "entry" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "sign-out-alt" },
                    }),
                    _c("a", { on: { click: _vm.onLogout } }, [
                      _vm._v(_vm._s(_vm.$t("nav.logout"))),
                    ]),
                  ],
                  1
                ),
                _c("li", { staticClass: "lang" }, [_c("choix-langue")], 1),
                _c("li", { staticClass: "copyright" }, [
                  _vm._v(
                    " Copyright " + _vm._s(new Date().getFullYear()) + " "
                  ),
                ]),
              ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }