var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "choix-langue" },
    _vm._l(_vm.languageArray, function (lang, i) {
      return _c("div", {
        key: `lang${i}`,
        class: lang + (lang === _vm.language ? " active" : ""),
        on: {
          click: function ($event) {
            return _vm.onChangeLanguage(lang)
          },
        },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }